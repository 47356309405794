import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const Nav = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        edges {
          node {
            id
            parent {
              ... on File {
                name
                relativeDirectory
              }
            }
          }
        }
      }
    }
  `);

  const edges = data.allMarkdownRemark.edges
    .filter(({ node }) => node.parent.relativeDirectory === "")
    .filter(({ node }) => node.parent.name !== "index");

  const pages = edges.map(({ node }) => node.parent.name);

  return (
    <nav className="gridl">
    <Link to="/">Home</Link>
    <Link to="/referenzen">Referenzen</Link>
      {pages.map((p) => (
        <Link key={p} to={`/${p}`}>{capitalize(p)}</Link>
      ))}
    </nav>
  );
};

export default Nav;
