import React from "react";
import { Link } from "gatsby";
import { StaticImage as Img } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import "./layout.css";
import "./fonts.css";

import Nav from "./Nav";

export const Row = ({ children }) => <div className="grid">{children}</div>;

export const Right = ({ children, ...other }) => (
  <div className="gridr" {...other}>
    {children}
  </div>
);
export const Left = ({ children, nav }) => (
  <div className="gridl">{children}</div>
);

const title = "braack Ingenieure für Tragwerksplanung";

const Layout = ({ children, Head }) => {
  const head = Head ? (
    Head
  ) : (
    <Right style={{ alignItems: "flex-end" }}>
      <h1>braack</h1>
      <p>Ingenieure für Tragwerksplanung</p>
    </Right>
  );

  return (
    <div className="center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta
          name="description"
          content="Christian Braack, 47623 Kevelaer. Beratender Ingenieur, 
Sachverständiger für konstruktiven Ingenieurbau, qualifizierter Tragwerksplane IK Bau NRW,
staatl. anerk. Sachverständiger Schall + Wärme IK-Bau NRW"
        />
      </Helmet>
      <div className="layout">
        <Img src="../static/images/header.jpg" layout="fullWidth" alt="" />
        <Row>
          <Left />
          {head}
        </Row>
        <Row>
          <Nav>
            <Link to="/">Home</Link>
          </Nav>
          <Right>{children}</Right>
        </Row>
      </div>
    </div>
  );
};

export default Layout;
